import moment from "moment";
import {
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  InputNumber,
  Input,
  Switch,
  Spin,
} from 'antd';
import React, { useEffect, useMemo, useState } from "react";
import { FaAmazon } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { VscDebugRestart } from 'react-icons/vsc';
import styled from 'styled-components';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as serviceCaller from "../../Services";
import MuiAlert from '@mui/material/Alert';
import { useSnackbar } from "notistack";
import { RightCircleOutlined, DownCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { CgAdd } from 'react-icons/cg';
import { async } from "q";
// import { Button, Modal } from 'antd';

const Wrapper = styled.div`
  .ant-row-middle {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
`;
const SwitchStyle = styled.div`
  .ant-switch-checked {
    background-color: #FF9900;
  }
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const UsersTableView = (props) => {
  // console.log("props",props);

  const [dataArray, setDataArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [SelectPlan, setSelectPlan] = useState();
  const [refresh, setRefresh] = useState(false);
  const [Opened, setOpened] = useState();
  const [pageSizeValue, setPageSizeValue] = React.useState();
  const [AddListModal, setAddListModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAsin, setAsin] = React.useState([]);
  const [loading, setloading] = React.useState( );


  useEffect(() => {
    setloading(props.userLoading);
  }, [props.userLoading]);

  useEffect(() => {
    if (window?.screen?.height > 900) {
      setPageSizeValue(15);
    } else {
      setPageSizeValue(10);
    }
  }, []);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setArrayDataforDeals();
    } else {
      setDataArray();

    }
  }, [props?.Tab, props?.refreshDeals, props?.data?.length, props]);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const setArrayDataforDeals = async () => {
    const dataData = [];
    await props?.data?.map((item, index) => {
      item.key = index;
      dataData?.push(item);
    })
    setDataArray(dataData);
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const columns = [
    // {
    //   title:"Actions", 
    //   width: 100,
    //   key: 'action',
    //   fixed:"left",
    //   render: (value) => {
    //     return(
    //       <div style={{display:"flex",alignItems:"center"}}>
    //         <Button type='link' target='_blank' href={`${value?.amazon_product_url}`}>
    //           <FaAmazon size={"120%"}/>
    //         </Button>
    //         <Button 
    //           type='link'
    //           style={{marginTop:"-8px"}}
    //           onClick={async()=>{
    //             if(props?.deals==="deleted"){
    //               const Restore_deal = await serviceCaller.restoreDeals(value?._id,value);
    //               handleClick();
    //               if(Restore_deal?.data?.restored){
    //                 // window.location.reload();
    //                 props.Refresh();
    //               }
    //             }else{
    //               const Delete_deal = await serviceCaller.deleteDeals(value?._id,value);
    //               handleClick();
    //               if(Delete_deal?.data?.deleted){
    //                 // window.location.reload();
    //                 props.Refresh();
    //               }
    //             }
    //           }}
    //         >
    //           {props?.deals==="deleted" ? (
    //             <VscDebugRestart size={"140%"}/>
    //           ):(
    //             <MdDelete size={"140%"}/>
    //           )}
    //         </Button>
    //       </div>
    //     )
    //   },
    // },
    {
      title: "Name",
      key: 'image',
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div>
            <span>{value?.first_name}</span>
          </div>
        )
      },
    },
    {
      title: "Email",
      key: 'image',
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div>
            <span>{value?.email}</span>
          </div>
        )
      },
    },
    {
      title: "KeyCloak Id",
      key: 'image',
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div>
            <span>{value?.kc_id}</span>
          </div>
        )
      },
    },
    {
      title: "Stripe Customer_id Id",
      key: 'image',
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div>
            <span>{value?.stripe_customer_id}</span>
          </div>
        )
      },
    },
  ];

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  // console.log("dataArray-->", dataArray);
  // console.log("isAsinLikes--->", isAsinLikes);

  return (
    <Wrapper>
      <Spin spinning={loading} tip="loading...">
        <div>
          {dataArray?.map((value) => (
            <>
              <Row align='middle' justify='start'>
                <Col xs={1} sm={1} md={1} lg={1} xxl={1} style={{ padding: "0px" }}>
                  <div style={{ display: "flex", alignItems: "center", fontSize: "18px" }}>
                    {Opened?._id === value?._id ? (
                      <DownCircleOutlined
                        onClick={e => {
                          setOpened(undefined);
                        }} />
                    ) : (
                      <RightCircleOutlined
                        onClick={e => {
                          console.log("value",value);
                          // setOpened(undefined);
                          setOpened(value);
                        }}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={22} sm={10} md={5} lg={5} xxl={5}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>{"Name"}</div>&nbsp;&nbsp;
                    <div style={{ fontSize: "12px" }}>{value?.first_name}</div>
                  </div>
                </Col>
                <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>{"Email"}</div> &nbsp;&nbsp;
                    <div style={{ fontSize: "12px" }}>{value?.email}</div>
                  </div>
                </Col>
                <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>{"KeyCloak Id"}</div> &nbsp;&nbsp;
                    <div style={{ fontSize: "12px" }}>{value?.kc_id}</div>
                  </div>
                </Col>
                <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>{"Stripe Id"}</div> &nbsp;&nbsp;
                    <div style={{ fontSize: "12px" }}>{value?.stripe_customer_id}</div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24} style={{marginTop:"1%"}}/>
                <Col xs={0} sm={0} md={1} lg={1} xxl={1}/>
                <Col xs={12} sm={10} md={5} lg={5} xxl={5}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      <Button
                        type="primary"
                        size="small"
                        onClick={
                          async () => {
                            const UserLikes = await serviceCaller.getLikes(value);
                            if (UserLikes?.data?.userLikes?.length > 0) {
                              setAsin(UserLikes?.data?.userLikes[0]);
                              setIsModalOpen(true);
                            }
                          }} > likes </Button>

                    </div> &nbsp;&nbsp;
                  </div>
                </Col>
                <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      <Button
                        type="primary"
                        size="small"
                        onClick={async () => {
                          const UserDisLikes = await serviceCaller.getDisLikes(value);
                          if (UserDisLikes?.data?.userdisLikes?.length > 0) {
                            setAsin(UserDisLikes?.data?.userdisLikes[0]);
                            setIsModalOpen(true);
                          }
                        }} > dislikes </Button>

                    </div> &nbsp;&nbsp;
                  </div>
                </Col>
                <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      <Button
                        type="primary"
                        size="small"
                        onClick={
                          async () => {
                            const UserPurchse = await serviceCaller.getPurchases(value);
                            if (UserPurchse?.data?.userpurchases?.length > 0) {
                              setAsin(UserPurchse?.data?.userpurchases[0]);
                              setIsModalOpen(true);
                            }
                          }}
                      > Purchases </Button>

                    </div> &nbsp;&nbsp;
                  </div>
                </Col>
                <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      <Button
                        type="primary"
                        size="small"
                        onClick={
                          async () => {
                            const UserFavorite = await serviceCaller.getFavorites(value);
                            if (UserFavorite?.data?.userfavorites?.length > 0) {
                              setAsin(UserFavorite?.data?.userfavorites[0]);
                              setIsModalOpen(true);
                            }
                          }
                        }> Favorites </Button>
                    </div> &nbsp;&nbsp;
                  </div>
                </Col>
              </Row>
              <div>
                {(Opened?._id === value?._id) && (
                  <div style={{ marginLeft: "50px" }}>
                    {Opened?.Subscription_lists.map((item, index) => (
                      <>
                        <Row align='middle'>
                          <Col xs={12} sm={12} md={1} lg={1} xxl={1}>
                            <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>
                              {index + 1}
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={5} lg={5} xxl={5}>
                            <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>
                              {item?.requestPlan?.replace(/_/gi, " ")}
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                            <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>{item?.tranInitStatus}</div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                            {item?.subscription_start &&(
                              <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>
                                Start : {(item?.subscription_start) ? moment(new Date(item?.subscription_start * 1000)).format("DD-MMM-YYYY") : "-"}
                              </div>
                            )}
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                            {item?.subscription_end &&(
                              <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>
                                End : {(item?.subscription_end) ? moment(new Date(item?.subscription_end * 1000)).format("DD-MMM-YYYY") : " - "}
                              </div>
                            )}
                          </Col>

                        </Row>
                      </>
                    ))}
                  </div>
                )}
              </div>
            </>
          ))}
          <Modal title={`list of asin ${isAsin?.length ?? 0}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div>
              {isAsin?.map((value) => (
                <p>{value?.asin}</p>
              ))}
            </div>
          </Modal>
        </div>
      </Spin>
    </Wrapper>
  )
}
export default UsersTableView;