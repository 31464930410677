import React, { useContext, useEffect, useState } from "react";
import DashboardIndex from './Dashboard';
import {
  Grid,
  Typography,
} from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";

import { createContext } from "react";


export const context = createContext();

function App() {
  const [keycloak, initialized] = useKeycloak();
  const [category, setCategory] = useState([
    "Automotive",
    "Automotive & Powersports",
    "Arts, Crafts & Sewing",
    "Baby",
    "Baby Products",
    "Beauty",
    "Beauty & Personal Care",
    "Books",
    "Camera & Photo",
    "Cds & Vinyl",
    "Cell Phones & Accessories",
    "Clothes, Shoes & Jewelry",
    "Clothing, Shoes & Jewelry",
    "Electronics ",
    "Grocery & Gourmet Food",
    "Health & Household ",
    "Home & Kitchen ",
    "Home Improvement ",
    "Industrial & Scientific",
    "Kitchen & Dining",
    "Luggage & Travel Accessories",
    "Movies and TV ",
    "Movies & TV",
    "Music & DVD",
    "Musical Instruments ",
    "Office Products",
    "Outdoors",
    "Patio, Lawn & Garden",
    "Personal Computers",
    "Pet Supplies",
    "Sports",
    "Sports & Outdoors",
    "Streaming Media Players",
    "Tools & Home Improvement",
    "Toys & Games",
    "Video Games",
    "Watches",
  ]);


  if (initialized && keycloak.authenticated) {
    if (!keycloak.realmAccess.roles.some((e) => e == "app-admin")) {
      return (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography>Your are not authorised</Typography>
          </Grid>
        </Grid>
      );
    }


    return (
      <>
        <div>
          <DashboardIndex/>
        </div>
      </>
    );
  } else {
    keycloak.login();
  }
}

export default App;
