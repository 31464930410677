const axios = require("axios");
const { useQuery } = require("react-query");
// const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../../keycloak");

const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV1 = process.env.REACT_APP_BASEURL_V1;

let headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + keycloak.default.token,
};

export const get=async(page_name = "deals",page = 1, page_size = 20, deal_type = "all", sort = ["updated_on"])=> {
  const url=`${baseURL}${page_name}`;
  const query={page,page_size,deal_type,sort}
  const data=await axios.get(url, {
    headers: headers,
    params: query,
  });
  return data;

}

export function getMailCount() {
}