import moment from "moment";
import {
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  InputNumber,
  Input,
  Switch,
  Divider
} from 'antd';
import React, { useEffect, useMemo, useState } from "react";
import { FaAmazon } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { VscDebugRestart } from 'react-icons/vsc';
import styled from 'styled-components';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as serviceCaller from "../../Services";
import MuiAlert from '@mui/material/Alert';
import { useSnackbar } from "notistack";
import { RightCircleOutlined, DownCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { CgAdd } from 'react-icons/cg';


const Wrapper = styled.div`
  .ant-row-middle {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
`;
const SwitchStyle = styled.div`
  .ant-switch-checked {
    background-color: #FF9900;
  }
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const FeedbackTable = (props) => {
  const [dataArray, setDataArray] = useState();
  const [open, setOpen] = React.useState(false);
  const [SelectPlan, setSelectPlan] = useState();
  const [refresh, setRefresh] = useState(false);
  const [Opened, setOpened] = useState();

  useEffect(() => {
    if (props?.data?.feed_backs?.length > 0) {
      setDataArray(props?.data?.feed_backs)
    }
  }, [props?.data?.feed_backs])

  return (
    <Wrapper>
      <div>
        {dataArray?.map((value) => (
          <>
            <Row align='middle' justify='start'>
              <Col xs={1} sm={1} md={1} lg={1} xxl={1} style={{ padding: "0px" }}>
                <div style={{ display: "flex", alignItems: "center", fontSize: "18px" }}>
                  {Opened?._id === value?._id ? (
                    <DownCircleOutlined
                      onClick={e => {
                        setOpened(undefined);
                      }} />
                  ) : (
                    <RightCircleOutlined
                      onClick={e => {
                        setOpened(undefined);
                        setOpened(value);
                      }}
                    />
                  )}
                </div>
              </Col>
              <Col xs={22} sm={10} md={5} lg={5} xxl={5}>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>{"Email"}</div>&nbsp;&nbsp;
                  <div style={{ fontSize: "12px" }}>{value?.email}</div>
                </div>
              </Col>
            </Row>
            <div>
              {(Opened?._id === value?._id) && (
                <div style={{ marginLeft: "50px" }}>
                  {Opened?.feedBack.map((item, index) => (
                    <>
                      <Row align='middle'>
                        <Col xs={12} sm={12} md={1} lg={1} xxl={1}>
                          <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>
                            {index + 1}
                          </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={0} xxl={0}>
                          <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>
                            {/* {item?.deal} */}
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xxl={5}>
                          <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>
                            {item?.asin}
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xxl={5}>
                          <div style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "16px" }}>
                            {item?.feedback}
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                          {/* <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>{item?.tranInitStatus}</div> */}
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                          {/* <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>
                              Start : {moment(new Date(item?.tranInitSubscriptionFromGateway?.current_period_end*1000)).format("DD-MMM-YYYY")}
                            </div> */}
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                          {/* <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>
                              End : {moment(new Date(item?.tranInitSubscriptionFromGateway?.current_period_start*1000)).format("DD-MMM-YYYY")}
                            </div> */}
                        </Col>
                      </Row>
                    </>
                  ))}
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </Wrapper>
  )
}
export default FeedbackTable;