import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider } from "notistack";
import { KeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

const reactQuery = new QueryClient();

ReactDOM.render(
  <KeycloakProvider
    keycloak={keycloak}
    LoadingComponent={<p>Loading...</p>}
    onEvent={(event, error) => {
      console.log({error});
    }}
    onTokens={(tokenData) => {
      localStorage.setItem("aoa_admin_token", tokenData?.token);
    }}
  >
    <QueryClientProvider client={reactQuery}>
      {/* <React.StrictMode> */}
      <SnackbarProvider maxSnack={1}>
        <App />
      </SnackbarProvider>
      {/* </React.StrictMode> */}
    </QueryClientProvider>
  </KeycloakProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
