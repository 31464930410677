
import React,{ useEffect, useMemo, useState } from "react";
import styled from 'styled-components';
import { useSnackbar } from "notistack";
import { 
    Button, 
    Row,
    Col, 
    Modal,
    Form,
    Input,
    InputNumber,
    Select,
} from "antd";
import "antd/dist/antd.css";
import ListTableView from "./plan_list_view";
import { useKeycloak } from "@react-keycloak/web";
import { createContext } from "react";
import * as serviceCaller from "../../Services/plans";

const Wrapper=styled.div`
  background-color: red;
`;
export const context = createContext();
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function ListView(props) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [keycloak, initialized] = useKeycloak();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [listCreateModal, setlistCreateModal] = useState(false);
  const [duplicatePlan, setDuplicatePlan] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [Plans, setPlans] = useState();

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    getPlans();
    setlistCreateModal(props?.AddPlan);
  }, [props?.AddPlan,refresh]);


  const getPlans=async(
    filter = {},
    page = 1,
    page_size = 10,
    deal_type = "all",
    sort = ["updated_on"]
  )=>{
      const Plans = await serviceCaller.getPlans(filter,page,page_size,deal_type,sort);
      setPlans(Plans?.data);
  }


  const addPlan=async(values)=>{
    try {
      await serviceCaller.addPlans(values);
      setRefresh(!refresh);
      props.onClose();
      enqueueSnackbar("Plan Added", {
        variant: "success",
      });
    } catch (error) {
      console.log("Error : ",error);
      enqueueSnackbar("error while add plan", {
        variant: "error",
      });
    }
  }
  return (
    <>
      <div>
        <div>
          <ListTableView 
            Refresh={refresh} 
            data={Plans?.docs}
            reload={()=>{
              setRefresh(!refresh);
            }}
          />
        </div>
      </div>
      <div>
        <Modal 
          title="Add list" 
          open={listCreateModal} 
          onClose={()=>props?.onClose()}
          okText="Create"
          cancelText="Cancel"
          onCancel={()=>props?.onClose()}
          onOk={() => {
            setDuplicatePlan(false);
            form.validateFields().then(async(values) => {
              values.plan=values.plan.toLowerCase().trim();
              const planDatas = await serviceCaller.getPlan(values);
              if(planDatas?.data){
                setDuplicatePlan(true);
              }else{
                addPlan(values);
              }
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <div>
            <Form
              form={form}
              name="register"
              onFinish={()=>{}}
              requiredMark={false}
              initialValues={{
                // residence: ['zhejiang', 'hangzhou', 'xihu'],
                // prefix: '86',
              }}
              scrollToFirstError
            >
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                  <Form.Item label="Plan" />
                </Col>
                <Col xs={12} sm={12} md={18} lg={18} xxl={18}>
                  <Form.Item
                    name="plan"
                    validateStatus={duplicatePlan && "error"}
                    help={duplicatePlan && <span>{"Already Exist"}</span>}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your plan!',
                      },
                    ]}
                  >
                    <Input 
                      onChange={()=>{
                        setDuplicatePlan(false);
                      }}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                  <Form.Item label="Limit" />
                </Col>
                <Col xs={12} sm={12} md={18} lg={18} xxl={18}>
                  <Form.Item
                    name="limit"
                    rules={[
                      {
                        required: true,
                        message: 'Please input the limit!',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: '100%',
                      }} 
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default ListView;
