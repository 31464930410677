const axios = require("axios");
const { useQuery } = require("react-query");
// const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");

const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV1 = process.env.REACT_APP_BASEURL_V1;

export function addPlans(plandetails) {
  
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL}plans`, plandetails, {
    headers: headers,
  });
}

export function getPlans(
  filter = {},
  page = 1,
  page_size = 10,
  deal_type = "all",
  sort = ["updated_on"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const data=axios.get(`${baseURL}plans`, {
    headers: headers,
    params: {
      // ...filter,
      page,
      page_size,
      deal_type,
      sort,
    },
  });
  return data;
}

export function getPlan (value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const data= axios.get(`${baseURL}plans/plan_valid`, {
    headers: headers,
    params: value,
  })
  return data;
}

export function getPricePlan(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const data= axios.get(`${baseURL}plans/list/valid`, {
    headers: headers,
    params: value,
  })
  return data;
}


export function addPlan_list(plandetails) {
  
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL}plans/add/list`, plandetails, {
    headers: headers,
  });
}

export function addStripeUser(stripeId) {
  
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL}deals/add-customer-from-stripe`, stripeId, {
    headers: headers,
  });
}
// edit deals

export function editPlan_list(planListId, planListData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.put(`${baseURL}plans/edit/list/${planListId}`, planListData, { headers });
}
// edit deals

export function deletePlan_list(planListId, planListData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.put(`${baseURL}plans/edit/list/${planListId}`, planListData, { headers });
}