
import React,{ useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { 
    Tabs,
    Button, 
    DatePicker, 
    Space, 
    version, 
    Modal,
    Pagination,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    Badge,
    Row,
    Select,
    Popconfirm,
    notification
} from "antd";
import "antd/dist/antd.css";
import LiveDealsTableView from "./live_deals_view";  
import DeletedDealsTableView from "./deleted_deals_view";  
import UsersTableView from "./users_view";  
import MailCountTableView from "./mail_open_count"
import FeedbackTable from "./feedback_view";
import { useKeycloak } from "@react-keycloak/web";
import { createContext } from "react";
import * as serviceCaller from "../../Services";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const context = createContext();
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function DealsView(props) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [keycloak, initialized] = useKeycloak();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [livedealsData, setLiveDealsData] = React.useState();
  const [UsersData, setUsersData] = React.useState([]);
  const [UserPage, setUserPage] = React.useState(1);
  const [UserPageSize, setUserPageSize] = React.useState(10);
  const [deleteddealsData, setDeletedDealsData] = React.useState();
  const [Tab, setTab] = React.useState();
  const [refreshDeals, setRefreshDeals] = React.useState(false);
  const [RefreshUsersData, setRefreshUsersData] = React.useState(false);
  const [refreshDeletedDeals, setRefreshDeletedDeals] = React.useState(false);
  const [FeedbackData, setFeedbackData] = React.useState([]); 
  const [IsSelectSupscriberType, setSelectSupscriberType] = useState(undefined);
  const [userLoading, setuserLoading] = useState(false);
  const [mailTriggerButton, setMailTriggerButton] = useState(false);
 
  useEffect(() => {
    console.log({props1:props.SelectSupscriberType});
    setSelectSupscriberType(props.SelectSupscriberType);
  }, [props.SelectSupscriberType]);

  useEffect(() => {
    console.log({props1:props});
    getUsersDatas();
  }, [props?.refresh,RefreshUsersData,Tab,UserPage,UserPageSize,props?.search,props.SelectSupscriberType]);

  useEffect(() => {
    getDealsDatas();
    getDeletedDealsDatas()
  }, [props?.refresh,refreshDeals,Tab , props?.searchdeals,props?.sort]);

  console.log("props?.searchdeals",props?.searchdeals);
  console.log("props?.sort",props?.sort);

  useEffect(()=>{
    getFeedbackData();
  },[props?.refresh,refreshDeals,Tab])
 
  const getDealsDatas=async(
    filter = {},
    page = 1,
    page_size = 10,
    deal_type = "all",
    searchdeals="",
    sort =""
  )=>{
      const LiveDeals = await serviceCaller.getDeals(filter,page,page_size,deal_type,
        searchdeals=props?.searchdeals,
        sort=props.sort,
        );
      setLiveDealsData(LiveDeals?.data);
  }

  const getUsersDatas=async(
    filter = {},
    page = UserPage,
    page_size = UserPageSize,
    deal_type = "all",
    sort = "created_on",
    search=props?.search,
    SelectSupscriberType= props.SelectSupscriberType ?? "usersList"
  )=>{
    try {
      console.log({IsSelectSupscriberType});
      setuserLoading(true);
      const Users = await serviceCaller.getUsers(filter,page,page_size,deal_type,sort,search,SelectSupscriberType);
      setuserLoading(false);
      console.log("Users : ",Users);
      setUsersData(Users?.data);
    } catch (error) {
      setuserLoading(false);
      console.log("error : ",error);
    }
  }



  const getDeletedDealsDatas=async(
    filter = {},
    page = 1,
    page_size = 10,
    deal_type = "all",
    sort = ["created_on"]
  )=>{
      const DeletedDeals = await serviceCaller.getDeletedDeals(filter,page,page_size,deal_type,sort);
      setDeletedDealsData(DeletedDeals?.data);
  }
  
  const getFeedbackData = async(
    page = 1,
    page_size = 10,
    search,
  )=>{
     const feedbackData = await serviceCaller.getFeedback(page,page_size,search);
      setFeedbackData(feedbackData?.data);
  }
console.log("UsersData-->",UsersData);
  return (  
    <>
      <div>
        <div className="card-container">
          <Tabs 
            defaultActiveKey="1" 
            type="card"
            onChange={(e)=>{
              setTab(e);
              props?.Tab(e);
            }}
            tabBarExtraContent={
              <div style={{paddingRight:"50px"}}>
              <Popconfirm
                title="Are you sure to send to mail?"
                placement="leftTop"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: 'red',
                    }}
                  />
                }
                onConfirm={async()=>{
                  setMailTriggerButton(true);
                  const LiveDeals = await serviceCaller.triggerMail();
                  if(parseInt(LiveDeals.status) === 200){
                    console.log({LiveDeals:LiveDeals.status});
                    setMailTriggerButton(false);
                    notification.success({
                      duration: 15,
                      message: "Mail Triggered",
                      description: "Paid Users are received our Mail"
                    });
                  }else{
                    notification.error({
                      duration: 15,
                      message: "Something went wrong",
                      description: "Please Check!"
                    });
                  }
                }}
              >
                <Button
                  size="middle"
                  type="primary"
                  // loading={mailTriggerButton}
                  style={{borderRadius:"15px",fontSize:"12px"}}
                >
                  {"Trigger Mail"}
                </Button>
              </Popconfirm>
              </div>
            }
          >
            <Tabs.TabPane 
              tab={
                <div style={{display:"flex",alignItems:"center"}}>
                  <span>{"live Deals"}</span> &nbsp;&nbsp;
                  <Badge count={livedealsData?.totalDocs} overflowCount={99999} style={{fontSize:"10px"}}/>
                </div>
              } 
              key="1"
            >
              <LiveDealsTableView 
                data={livedealsData?.docs} 
                deals={"live"}
                Tab={Tab}
                reloadDeals={refreshDeals}
                AllData={livedealsData}
                PaginationUpdate={(page=1,pagesize=10)=>{
                  getDealsDatas({},page,pagesize,"",[])
                }}
                OnEdit={(value)=>{
                  props?.OnEdit(value);
                }}
                Refresh={()=>{
                  setRefreshDeals(!refreshDeals);
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane 
              tab={
                <div style={{display:"flex",alignItems:"center"}}>
                  <span>{"Deleted Deals"}</span> &nbsp;&nbsp;
                  <Badge count={deleteddealsData?.totalDocs} overflowCount={99999} style={{fontSize:"10px"}}/>
                </div>
              } 
              key="2"
            >
              <DeletedDealsTableView 
                data={deleteddealsData?.docs} 
                AllData={deleteddealsData}
                reloadDeals={refreshDeals}
                Tab={Tab}
                deals={"deleted"}
                PaginationUpdate={(page=1,pagesize=10)=>{
                  getDeletedDealsDatas({},page,pagesize,"",[])
                }}
                Refresh={()=>{
                  setRefreshDeals(!refreshDeals);
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane 
              tab={
                <div style={{display:"flex",alignItems:"center"}}>
                  <span style={{textTransform:"capitalize"}}>{"Users"}{props.SelectSupscriberType ? props.SelectSupscriberType==="default" ? "" : ` - ${props.SelectSupscriberType}` : ""}</span> &nbsp;&nbsp;
                  <Badge count={UsersData?.totalDocs} overflowCount={99999} style={{fontSize:"10px"}}/>
                </div>
              } 
              key="3"
            >
              <div 
                style={{
                  height:"76vh",
                  overflowY:"scroll",
                  paddingTop:"2%",
                  paddingBottom:"2%",
                }}>
                <UsersTableView 
                  data={UsersData?.docs} 
                  AllData={UsersData}
                  SelectSupscriberType={props.SelectSupscriberType}
                  reloadDeals={refreshDeals}
                  userLoading={userLoading}
                  Tab={Tab}
                  // PaginationUpdate={(page=1,pagesize=10)=>{
                  //   getUsersDatas({},page,pagesize,"",[])
                  // }}
                  Refresh={()=>{
                    setRefreshUsersData(!RefreshUsersData);
                  }}y
                />
              </div>
              <div style={{textAlign:"center",paddingTop:"1%"}}>
                <Pagination
                  size="default"
                  defaultCurrent={1}
                  defaultPageSize={10}
                  total={UsersData?.totalDocs}
                  responsive={true}
                  onChange={(value, pageSize) => {
                    setUserPage(value);
                    setUserPageSize(pageSize)
                  }}
                />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane 
              tab={
                <div style={{display:"flex",alignItems:"center"}}>
                  <span>{"Feedback"}</span> &nbsp;&nbsp;
                  {/* <Badge count={FeedbackData?.data?.totalDocs} overflowCount={99999} style={{fontSize:"10px"}}/> */}
                </div>
              } 
              key="4"
            >
              <div 
                style={{
                  height:"76vh",
                  overflowY:"scroll",
                  paddingTop:"2%",
                  paddingBottom:"2%",
                }}>
                <FeedbackTable 
                  data={FeedbackData} 
                  AllData={FeedbackData}
                  reloadDeals={refreshDeals}
                  Tab={Tab}
                  // PaginationUpdate={(page=1,pagesize=10)=>{
                  //   getUsersDatas({},page,pagesize,"",[])
                  // }}
                  Refresh={()=>{
                    setRefreshDeals(!refreshDeals);
                  }}
                />
              </div>

            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="Mail Open Count" key="5">
              <MailCountTableView data={[]}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Report" key="6">
              <MailCountTableView data={[]}/>
            </Tabs.TabPane> */}
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default DealsView;
