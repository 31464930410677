import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  AppBar,
  Grid,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Col,
  Tooltip,
  Button,
  Row,
  Avatar,
  Modal,
  Form,
  Input,
  Spin,
  Switch,
  Select,
  notification,
  Badge
} from "antd";
import { PlusCircleOutlined, UserAddOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import XLSX from "xlsx";
import { useSnackbar } from "notistack";
import * as Httprequest from "../component/http_request";
import DealsListIcon from "../assets/deal.png";
import * as serviceCaller from "../Services";
import PlanListIcon from "../assets/price-list.png";
import PlanView from "./Plan_board";
import DealsView from "./Deals_board";
import { CgChevronDoubleLeft } from "react-icons/cg";
import * as StripeUser from "../Services/plans";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
const Wrapper = styled.div`
  .ant-modal-body {
    padding-left: 24px;
    font-size: 14px;
    padding-right: 24px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .ant-table-content{
    overflow: inherit !important; 
      height: 76vh !important;
  }
`;

const style = {
  background: "#0092ff",
  padding: "8px 0",
};
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
const DashboardIndex = (props) => {
  const [form] = Form.useForm();
  const [Stripeform] = Form.useForm();
  const { Option } = Select;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [bulkUploads, setBulkUploads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dashBoard, setDashBoard] = useState("deals");
  const [dealsData, setDealsData] = React.useState();
  const [addPlan, setAddPlan] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [Category, setCategory] = useState([]);
  const [EditData, setEditData] = useState(false);
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchdeals, setSearchdeals] = useState("");
  const [tabValue, setTabValue] = useState("1");
  let [sort, setSort] = useState("default_sort");
  const [SelectSupscriberType, setSelectSupscriberType] = useState("usersList");
  const [stripeSubModal, setStripeSubModal] = useState(false);
  const [stripeSubscription, setStripeSubscription] = useState([]);
  const [SuscriptionStatus, setSuscriptionStatus] = useState("active");
  const [SuscriptionStatusChange, setSuscriptionStatusChange] = useState(false);

  useEffect(() => {
    getDealsDatas();
    getCategoryDatas();
  }, []);

  useEffect(() => {
    console.log("props : ", props);
  }, [props]);

  const handleSort = (e) => {
    const selects = e?.target?.value;
    setSort(selects);
    console.log(">>?", selects);
    // console.log(">today", selects);
  };
  const HandleUsersTYpeFilter = (value) => {
    try {
      setSelectSupscriberType(value);
    } catch (error) {
      console.log("error : ", error);
    }
  };
  useEffect(() => {
    getDealsDatas();
  }, [searchdeals, sort]);

  const getDealsDatas = async () => {
    const res = await serviceCaller.getDeals();
    setDealsData(res?.data?.docs);
  };
  console.log("dealdata",);

  const getCategoryDatas = async () => {
    const res = await serviceCaller.getCategory();
    setCategory(res?.data?.docs)
  };

  // bulk deal upload
  const bulkDealUpload = (e) => {
    let files = e.target.files;
    if (files && files[0]) setBulkUploads({ file: files[0] });
  };

  // bulk deals upload
  const bulkDeals = async () => {
    try {
      setLoading(true);
      // convert file to json
      const reader = new FileReader();

      const rABS = !!reader.readAsBinaryString;
      reader.onload = async (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dealList = XLSX.utils.sheet_to_json(ws);
        let datafilters = dealList.filter(
          (edit) => edit.plan_type == "Silver" || edit.plan_type == "silver"
        );
        let datafiltersz = dealList.filter(
          (edit) => edit.plan_type == "Gold" || edit.plan_type == "gold"
        );
        let res = await serviceCaller.uploadDeal(dealList);
        console.log({ res: res.data });
        if (!res.data.dealsError) {
          if (res.status == 200) {
            setLoading(false);
            enqueueSnackbar(res.data.message, {
              variant: "success",
            });
            window.location.reload();
          } else {
            setLoading(false);
            enqueueSnackbar(res.data.message, {
              variant: "error",
            });
          }
        } else {
          console.log("First Object : ", res.data.message);
          setLoading(false);
          notification.error({
            duration: 15,
            message: "Deals Not uploaded",
            description: res.data.message
          });
        }
      };

      if (rABS) {
        reader.readAsBinaryString(bulkUploads.file);
      } else {
        reader.readAsArrayBuffer(bulkUploads.file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function toggleModal() {
    setIsOpen(!isOpen);
    console.log(isOpen, "isOpen");
  }

  const submitSingleDeal = async (values) => {
    console.log("valuesservicecaller", values);
    let response = await serviceCaller.addDeal(values);
    if (response.status == 200) {
      setLoading(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
      });
      window.location.reload();
    } else {
      setLoading(false);
      enqueueSnackbar(response.data.message, {
        variant: "error",
      });
    }
    console.log("Finish:", values);
    form.resetFields();

  };

  return (
    <Wrapper>
      <Spin
        spinning={(stripeSubModal && stripeSubscription.length === 0) ? true : false}
        tip={
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {"Connecting with Stripe, Please Wait ..."}
          </div>
        }
      >
        <div style={{ width: "99%" }}>
          <div style={{ margin: "10px" }}>
            <Row gutter={24}>
              <Col className="gutter-row" xs={5} sm={5} md={5} lg={5} xxl={5}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                    ADMIN DASHBOARD
                  </div>
                  {dashBoard !== "deals" && (
                    <div>
                      <Button
                        type="link"
                        onClick={() => {
                          setAddPlan(true);
                        }}
                      >
                        <PlusCircleOutlined />
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          setAddUser(true);
                        }}
                      >
                        <UserAddOutlined />
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
              <Col className="gutter-row" xs={5} sm={5} md={5} lg={5} xxl={5}>
                {dashBoard === "deals" && (
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      type="file"
                      onChange={(e) => bulkDealUpload(e)}
                      inputProps={{ accept: ".xls,.xlsx" }}
                    />
                    <Button size="small" onClick={() => bulkDeals()}>
                      Upload
                    </Button>
                  </div>
                )}
              </Col>

              <Col className="gutter-row" xs={11} sm={11} md={11} lg={11} xxl={11}>
                {dashBoard === "deals" && (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                    {tabValue === "1" && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          toggleModal
                        }
                      >
                        ADD NEW DEAL
                      </Button>
                    )}
                    {tabValue === "3" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%"
                        }}
                      >
                        <Input
                          value={search}
                          placeholder="Search user"
                          style={{ width: "40%" }}
                          onChange={(e) => {
                            setSearch(e?.target?.value);
                          }}
                        />
                        <Select
                          value={SelectSupscriberType}
                          onChange={(value) => {
                            HandleUsersTYpeFilter(value);
                          }}
                          placeholder="Select Users type"
                          style={{ width: "25%" }}
                        >
                          <option value="usersList">{"Users List"}</option>
                          <option value="default">{"Default"}</option>
                          <option value="subscribed">{"Subscribed"}</option>
                          <option value="unsubscribed">{"Unsubscribed"}</option>
                        </Select>
                        <Button
                          type="primary"
                          style={{
                            borderRadius: "10px",
                            width: "20%",
                            fontSize: "12px"
                          }}
                          onClick={async () => {
                            setStripeSubModal(true);
                            setStripeSubscription([]);
                            let updateSubscription = await serviceCaller.UpdateSubscription();
                            console.log({ updateSubscription });
                            notification.success({
                              message: "Subscription Updated",
                            });
                            setStripeSubModal(false);
                          }}
                        >
                          Update Subscription
                        </Button>
                        <Button
                          type="link"
                          style={{
                            fontSize: "25px",
                            padding: "0px",
                            margin: "0px",
                            display: "flex",
                            alignItems: "end"
                          }}
                          onClick={async () => {
                            setStripeSubModal(true);
                            if (stripeSubscription.length === 0) {
                              setSuscriptionStatusChange(true);
                              let getStripeSubscription = await serviceCaller.getStripeSubscription();
                              console.log({ getStripeSubscription })
                              setStripeSubscription(getStripeSubscription.data.StripeSubscriptions);
                              setSuscriptionStatusChange(false);
                            }
                          }}
                        >
                          <EyeOutlined />
                        </Button>
                      </div>
                    )}
                    {tabValue === "1" && (
                      <>
                        <Input
                          placeholder="Search Deals"
                          style={{ width: "50%" }}
                          onChange={(e) => {
                            setSearchdeals(e?.target?.value);
                          }}
                        />
                        <select
                          className="category-dropdown"
                          onChange={handleSort}
                        >
                          <option selected value="all">
                            Default Sort
                          </option>
                          <option value="a">Store A to Z</option>
                          <option value="z">Store Z to A</option>
                        </select>
                      </>
                    )}
                  </div>
                )}
              </Col>

              <Col className="gutter-row" xs={1} sm={1} md={1} lg={1} xxl={1}>
                <Tooltip placement="topLeft" title={"Deals list"}>
                  <Button
                    type="link"
                    onClick={() => {
                      setDashBoard("deals");
                    }}
                  >
                    <Avatar src={DealsListIcon} />
                  </Button>
                </Tooltip>
              </Col>
              <Col className="gutter-row" xs={1} sm={1} md={1} lg={1} xxl={1}>
                <Tooltip placement="topLeft" title={"Plan list"}>
                  <Button
                    type="link"
                    onClick={() => {
                      setDashBoard("plans");
                    }}
                  >
                    <Avatar src={PlanListIcon} />
                  </Button>
                </Tooltip>
              </Col>
              <Col className="gutter-row" xs={1} sm={1} md={1} lg={1} xxl={1} />
            </Row>
          </div>
          <div>
            {dashBoard === "deals" ? (
              <>
                <DealsView
                  view={dashBoard}
                  refresh={refresh}
                  search={search}
                  searchdeals={searchdeals}
                  SelectSupscriberType={SelectSupscriberType}
                  sort={sort}
                  Tab={(value) => {
                    setTabValue(value);
                  }}
                  OnEdit={(value) => {
                    console.log("Edit value : ", value)
                    if (value) {
                      setEditData(value);
                      setIsOpen(true);
                      form.setFieldsValue(value);
                    } else {
                      setEditData(undefined);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <PlanView
                  view={dashBoard}
                  AddPlan={addPlan}
                  onClose={() => {
                    setAddPlan(!addPlan);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <Modal
          title={
            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
              <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <span>
                  {`Stripe Subscriptions `}
                </span>&nbsp;&nbsp;
                <span>
                  <Badge count={stripeSubscription.length}>
                    <Avatar shape="circle" size="small">
                      <UserOutlined />
                    </Avatar>
                  </Badge>
                </span>
              </div>
              <div style={{ width: "50%", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                <div>
                  {" "}
                </div>
                <Select
                  showSearch
                  allowClear
                  disabled={SuscriptionStatusChange}
                  value={SuscriptionStatus}
                  placeholder="Select Status"
                  optionFilterProp="label"
                  style={{ width: "50%" }}
                  onChange={async (data) => {
                    setSuscriptionStatusChange(true);
                    console.log({ data });
                    setSuscriptionStatus(data);
                    let getStripeSubscription = await serviceCaller.getStripeSubscription(SuscriptionStatus);
                    console.log({ getStripeSubscription })
                    setStripeSubscription(getStripeSubscription.data.StripeSubscriptions);
                    setSuscriptionStatusChange(false);
                  }}
                >
                  <Option key={1} value={"all"} label={`all`}>
                    All
                  </Option>
                  <Option key={2} value={"active"} label={`active`}>
                    Active
                  </Option>
                  <Option key={3} value={"past_due"} label={`past_due`}>
                    Past Due
                  </Option>
                  <Option key={4} value={"unpaid"} label={`unpaid`}>
                    Unpaid
                  </Option>
                  <Option key={5} value={"canceled"} label={`canceled`}>
                    Canceled
                  </Option>
                  <Option key={6} value={"incomplete"} label={`incomplete`}>
                    In-Complete
                  </Option>
                  <Option key={7} value={"incomplete_expired"} label={`incomplete_expired`}>
                    Incomplete Expired
                  </Option>
                  <Option key={8} value={"trialing"} label={`trialing`}>
                    Trialing
                  </Option>
                  <Option key={9} value={"paused"} label={`paused`}>
                    Paused
                  </Option>
                  <Option key={10} value={"all"} label={`all`}>
                    All
                  </Option>
                  <Option key={11} value={"ended"} label={`ended`}>
                    Ended
                  </Option>
                </Select>
                <div>
                  {" "}
                </div>
              </div>
            </div>
          }
          open={stripeSubModal}
          centered
          width={"80%"}
          bodyStyle={{ padding: "0px", paddingLeft: "20px", paddingRight: "20px" }}
          onClose={() => setStripeSubModal(false)}
          onCancel={() => setStripeSubModal(false)}
          onOk={() => {
            setStripeSubModal(false);
          }}
          maskClosable={false}
        >
          <Spin
            spinning={SuscriptionStatusChange}
            tip={
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                {"Connecting with Stripe, Please Wait ..."}
              </div>
            }
          >
            <Row
              align='middle'
              justify='start'
              style={{
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                padding: "10px",
                borderRadius: "10px",
                width: "99%",
                fontWeight: "bold"
              }}
            >
              <Col xs={1} sm={1} md={1} lg={1} xxl={1} style={{ padding: "0px" }}>
                <span>{"No"}</span>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xxl={6} style={{ padding: "0px" }}>
                <span>{"Email"}</span>
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xxl={5} style={{ padding: "0px" }}>
                <span>{"Plan Id"}</span>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xxl={6} style={{ padding: "0px" }}>
                <span>{"Subscription Id"}</span>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xxl={3} style={{ padding: "0px" }}>
                <span>{"Start Date"}</span>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xxl={3} style={{ padding: "0px" }}>
                <span>{"End Date"}</span>
              </Col>
            </Row>
            <div
              style={{
                height: "70vh",
                overflow: "hidden",
                overflowY: "auto"
              }}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24} style={{ padding: "3px" }} />
              </Row>
              {stripeSubscription.map((item, index) => (
                <div>
                  <Row
                    align='middle'
                    justify='start'
                    style={{
                      boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Col xs={1} sm={1} md={1} lg={1} xxl={1} style={{ padding: "0px" }}>
                      {index + 1}
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xxl={6} style={{ padding: "0px" }}>
                      {item.email}
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xxl={5} style={{ padding: "0px" }}>
                      {item.requestPlan}
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xxl={6} style={{ padding: "0px" }}>
                      {item.subscriptionId}
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xxl={3} style={{ padding: "0px" }}>
                      {moment(new Date(item?.subscription_start * 1000)).format("DD-MMM-YYYY")}
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xxl={3} style={{ padding: "0px" }}>
                      {moment(new Date(item?.subscription_end * 1000)).format("DD-MMM-YYYY")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xxl={24} style={{ padding: "3px" }} />
                  </Row>
                </div>
              ))}
            </div>
          </Spin>
        </Modal>


        <Modal
          title="Add User from Stripe"
          open={addUser}
          onClose={() => setAddUser(false)}
          okText="Create"
          cancelText="Cancel"
          onCancel={() => setAddUser(false)}
          onOk={() => {
            Stripeform.validateFields().then(async (values) => {
              const planDatas = await StripeUser.addStripeUser(values);
              if (planDatas?.status === 200) {
                setAddUser(false);
                form?.resetFields();
                notification.success({
                  message: planDatas?.data?.message,
                });
              } else {
                notification.error({
                  message: planDatas?.data?.message,
                });
              }
            })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <div>
            <Form
              form={Stripeform}
              name="register"
              onFinish={() => { }}
              requiredMark={false}
              initialValues={
                {
                  // residence: ['zhejiang', 'hangzhou', 'xihu'],
                  // prefix: '86',
                }
              }
              scrollToFirstError
            >
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                  <Form.Item label="Subscription Id" />
                </Col>
                <Col xs={12} sm={12} md={18} lg={18} xxl={18}>
                  <Form.Item
                    name="subscription_id"
                    rules={[
                      {
                        required: true,
                        message: "Please input your session id",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>

        {/* single deal upload modal */}

        <Wrapper>
          <Modal
            title="Add Single Deal"
            open={isOpen}
            centered
            width={800}
            bodyStyle={{ padding: "0px", paddingLeft: "20px", paddingRight: "20px" }}
            onClose={() => {
              form.resetFields();
              setIsOpen(false);
            }}
            onCancel={() => {
              form.resetFields();
              setIsOpen(false);
            }}
            footer={null}
          >
            <div>
              <Form
                form={form}
                name="register"
                colon={false}
                onFinish={async (values) => {
                  setButtonLoading(true);
                  // values.is_restricted = values.is_restricted === "yes" ? true :  false
                  // values.is_hazmat = values.is_hazmat === "yes" ? true : false
                  if (EditData) {
                    let response = await serviceCaller.UpdateDeals(EditData?._id, values);
                    console.log("response from update : ", response);
                    if (response?.status === 200) {
                      notification.success({
                        message: response?.data?.message,
                      });
                      setEditData(undefined);
                      setRefresh(!refresh);
                      setIsOpen(false);
                      setButtonLoading(false);
                      form.resetFields();
                      window.location.reload();
                    } else {
                      setRefresh(!refresh);
                      setButtonLoading(false);
                      notification.error({
                        message: response?.data?.message,
                      });
                    }
                  } else {
                    setRefresh(!refresh);
                    setButtonLoading(false);
                    console.log("val", values);
                    submitSingleDeal(values)
                  }
                }}
                requiredMark={false}
                initialValues={
                  {
                    // residence: ['zhejiang', 'hangzhou', 'xihu'],
                    // prefix: '86',
                  }
                }
                scrollToFirstError
              >
                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Product Name" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter product name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Category" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="category_id"
                      rules={[
                        {
                          required: true,
                          message: "Please enter category",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                      >
                        {Category?.map((item) => (
                          <Option value={item?._id}>{item?.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Amazon Price" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="amazon_price"
                      rules={[
                        {
                          required: true,
                          message: "Please enter amazonPrice",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Store Price" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="store_price"
                      rules={[
                        {
                          required: true,
                          message: "Please enter storePrice",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Net Profit" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="net_profit"
                      rules={[
                        {
                          required: true,
                          message: "Please enter netProfit",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Is Restricted" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="is_restricted"
                      rules={[
                        {
                          required: true,
                          message: "Please enter isRestricted",
                        },
                      ]}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Is Hazmat" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="is_hazmat"
                      rules={[
                        {
                          required: true,
                          message: "Please enter isHazmat",
                        },
                      ]}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Amazon Rank" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="ninety_days_rank"
                      rules={[
                        {
                          required: true,
                          message: "Please enter amazonRank",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Store Name" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="store_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter storeName",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="FBA Seller" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="fba_seller_count"
                      rules={[
                        {
                          required: true,
                          message: "Please enter fbaSeller",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="FBM Seller" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="fbm_seller_count"
                      rules={[
                        {
                          required: true,
                          message: "Please enter fbmSeller",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="UPC Code" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="upc_ean"
                      rules={[
                        {
                          required: true,
                          message: "Please enter upcCode",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="ROI" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="roi"
                      rules={[
                        {
                          required: true,
                          message: "Please enter ROI",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="ASIN" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="asin"
                      rules={[
                        {
                          required: true,
                          message: "Please enter ASIN",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="BSR" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="bsr"
                      rules={[
                        {
                          required: true,
                          message: "Please enter BSR",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="List Id" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="plan_list_id"
                      rules={[
                        {
                          required: true,
                          message: "Please enter List Id",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="EST Montly Sale" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="estimated_monthly_sales"
                      rules={[
                        {
                          required: true,
                          message: "Please enter EST Montly Sale",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Deal Type" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="plan_type"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Deal Type",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={64} xxl={4}>
                    <Form.Item label="Shipping Note" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="shipping_notes"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Shipping Note",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Notes" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Notes",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} align="middle">
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Promotion" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    <Form.Item
                      name="promotion_code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter promotion",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                    <Form.Item label="Image" />
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                    {/* <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload> */}
                    <Form.Item
                      name="image"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          type: "url",
                          warningOnly: true,
                        },
                        {
                          type: "string",
                          min: 6,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} align="middle" style={{ padding: "0px" }}>
                  <Col xs={12} sm={12} md={20} lg={20} xxl={20} style={{ padding: "0px" }}></Col>
                  <Col xs={12} sm={12} md={4} lg={4} xxl={4} style={{ padding: "0px" }}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={ButtonLoading}
                        className="login-form-button"
                      >
                        {EditData ? "Update Deal" : "Add Deal"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
        </Wrapper>
      </Spin>
    </Wrapper>
  );
};

export default DashboardIndex;
