const axios = require("axios");
const { useQuery } = require("react-query");
// const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");

const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV1 = process.env.REACT_APP_BASEURL_V1;

// edit deals
export function UpdateDeals(planListId, planListData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.put(`${baseURL}deals/${planListId}`, planListData, { headers });
}

export function deleteDeals(planListId, planListData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.put(`${baseURL}deals/delete/${planListId}`, planListData, { headers });
}

export function UpdateSubscription() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const data = axios.get(`${baseURL}deals/update/subscription`, {
    headers: headers,
    params: {},
  });
  return data;
}

export function getStripeSubscription(SuscriptionStatus) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const data = axios.get(`${baseURL}deals/stripe/subscription`, {
    headers: headers,
    params: {
      SuscriptionStatus:SuscriptionStatus,
    },
  });
  return data;
}
export function restoreDeals(planListId, planListData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.put(`${baseURL}deals/restore/${planListId}`, planListData, { headers });
}
export function getUsers(
  filter = {},
  page = 1,
  page_size = 10,
  deal_type = "all",
  sort = ["updated_on"],
  search,
  SelectSupscriberType
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log("headers--->", headers);
  const data = axios.get(`${baseURL}users`, {
    headers: headers,
    params: {
      // ...filter,
      page,
      page_size,
      deal_type,
      sort,
      search,
      SelectSupscriberType
    },
  });
  // console.log("data--->",data);
  return data;
}
export function getLikes(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  // console.log("headers-->",headers);

  const data = axios.get(`${baseURL}users/likes`, {
    headers: headers,
    params: {
      user_id:value?._id
    },

  });
 console.log("data-->",data);
  return data;
};

export function getDisLikes(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  // console.log("headers-->",headers);

  const data = axios.get(`${baseURL}users/dislikes`, {
    headers: headers,
    params: {
      user_id:value?._id
    },

  });
//  console.log("data-->",data);
  return data;
};
export function getPurchases(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  // console.log("headers-->",headers);

  const data = axios.get(`${baseURL}users/purchases`, {
    headers: headers,
    params: {
      user_id:value?._id
    },

  });
//  console.log("data-->",data);
  return data;
};

export function getFavorites(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  // console.log("headers-->",headers);

  const data = axios.get(`${baseURL}users/favorites`, {
    headers: headers,
    params: {
      user_id:value?._id
    },

  });
//  console.log("data-->",data);
  return data;
};



export function getFeedback(
  page = 1,
  page_size = 10,
  search,
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const data = axios.get(`${baseURL}deals/feedbacks`, {
    headers: headers,
    params: {
      page,
      page_size,
      search
    },
  });

  return data;
};

export function getDeals(
  filter = {},
  page = 1,
  page_size = 10,
  deal_type = "all",
  searchdeals,
  sort,
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const data = axios.get(`${baseURL}deals`, {
    headers: headers,
    params: {
      // ...filter,
      page,
      page_size,
      deal_type,
      searchdeals,
      sort,
      
    },
  });
  return data;
}

export function getCategory() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const data = axios.get(`${baseURL}categories/getall/category`, {
    headers: headers,
    params: {},
  });
  return data;
}

export function getDeletedDeals(
  filter = {},
  page = 1,
  page_size = 10,
  deal_type = "all",
  sort = ["updated_on"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const data = axios.get(`${baseURL}deals/deleted`, {
    headers: headers,
    params: {
      // ...filter,
      page,
      page_size,
      deal_type,
      sort,
    },
  });
  return data;
}

export function getMailCount(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const datamail = axios.get(`${baseURLV1}mailcount`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
  return datamail;
}

export function getReport(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV1}counters`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export function useDeals(
  queryName,
  filter = {},
  page = 1,
  page_size = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return useQuery(
    [
      queryName,
      {
        page: 0,
        page_size: 10,
        sort: "-_id",
        // filter: {},
      },

    ],
    async () =>
      await axios.get(`${baseURL}deals`, {
        headers: headers,
        params: {
          ...filter,
          page,
          page_size,
          sort,
        },
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 300000,
    }
  );
}

export function addDeal(dealData) {
  console.log("dealsdata", dealData);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.post(`${baseURL}deals/createdeals`, dealData, {
    headers: headers,
  });
}

export function uploadDeal(dealData) {
  console.log("uploaddealdata", dealData);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL}deals/upload`, dealData, {
    headers: headers,
  });
}


export function triggerMail() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL}deals/trigger/Mail`, {}, {
    headers: headers,
  });
}


export function uploadDealFiles(filesData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.post(`${baseURLV1}file`, filesData, {
    headers: headers,
  });
}

export function uploadProductImage(dealId, filesData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.put(`${baseURL}deals/${dealId}/image`, filesData, {
    headers: headers,
  });
}

// edit deals

export function editDeals(dealId, dealData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.put(`${baseURL}deals/${dealId}`, dealData, { headers });
}

// disable deals


// download deals

export function downloadDeals(dealId, dealData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.post(`${baseURL}deals/downloads${dealId}`, dealData, { headers });
}

export function disableDeals(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.patch(`${baseURL}deals`, data, {
    headers,
  });
}

