import moment from "moment";
import {
  Avatar,
  Switch,
  Pagination,
  Empty,
  Button,
  Table,
  Row,
  Col,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { EditOutlined, EditTwoTone } from "@ant-design/icons";
import { FaAmazon } from "react-icons/fa";
import { MdOutlineStorefront } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { VscDebugRestart } from "react-icons/vsc";
import styled from "styled-components";
// import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as serviceCaller from "../../Services";
import MuiAlert from "@mui/material/Alert";

const Wrapper = styled.div`
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    /* padding: 8px 8px; */
    padding: 5px;
  }
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const DealsViewTable = (props) => {
  const [dataArray, setDataArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [pageSizeValue, setPageSizeValue] = React.useState();
  const [EditDeal, setEditDeal] = React.useState(undefined);

  useEffect(() => {
    if (window?.screen?.height > 900) {
      setPageSizeValue(15);
    } else {
      setPageSizeValue(10);
    }
  }, []);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setArrayDataforDeals();
    } else {
      setDataArray();
    }
  }, [props?.Tab, props?.refreshDeals, props?.data?.length, props]);

  const setArrayDataforDeals = async () => {
    const dataData = [];
    await props?.data?.map((item, index) => {
      item.key = index;
      dataData?.push(item);
    });
    setDataArray(dataData);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const columns = [
    {
      title: "Actions",
      width: 190,
      key: "action",
      fixed: "left",
      render: (value) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="link"
              target="_blank"
              href={`${value?.amazon_product_url}`}
            >
              <FaAmazon />
            </Button>
            <Button
              type="link"
              target="_blank"
              href={`${value?.store_product_url}`}
            >
              <MdOutlineStorefront />
            </Button>
            <Button
              type="link"
              style={{ marginTop: "-5px" }}
              onClick={() => {
                console.log("value.is_hazmat : ", value.is_hazmat);
                console.log("value.is_restricted : ", value.is_restricted);
               
                // value.is_hazmat=value.is_hazmat==="yes" ? true : false;
                // value.is_restricted=value.is_restricted==="yes" ? true : false;
                console.log("value : ", value);
                props?.OnEdit(value);
              }}
            >
              <AiTwotoneEdit />
            </Button>

            <Button
              type="link"
              style={{ marginTop: "-5px" }}
              onClick={async () => {
                if (props?.deals === "deleted") {
                  const Restore_deal = await serviceCaller.restoreDeals(
                    value?._id,
                    value
                  );
                  handleClick();
                  if (Restore_deal?.data?.restored) {
                    // window.location.reload();
                    props.Refresh();
                  }
                } else {
                  const Delete_deal = await serviceCaller.deleteDeals(
                    value?._id,
                    value
                  );
                  handleClick();
                  if (Delete_deal?.data?.deleted) {
                    // window.location.reload();
                    props.Refresh();
                  }
                }
              }}
            >
              {props?.deals === "deleted" ? <VscDebugRestart /> : <MdDelete />}
            </Button>
          </div>
        );
      },
    },
    {
      title: "Product Image",
      key: "image",
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div style={{ textAlign: "center" }}>
            {value?.image ? (
              <Avatar src={value?.image} />
            ) : (
              <Avatar
                style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }}
                size="large"
              >
                {value?.name?.charAt(0)}
              </Avatar>
            )}
          </div>
        );
      },
    },
    {
      title: "Product Name",
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Asin",
      width: 150,
      dataIndex: "asin",
      key: "asin",
      fixed: "left",
    },
    {
      title: "Category Name",
      key: "c_name",
      width: 150,
      fixed: "left",
      render: (value) => {
        return (
          <>
            <div>{value?.cat_lists[0]?.name}</div>
          </>
        );
      },
    },
    {
      title: "Store Name",
      width: 150,
      dataIndex: "store_name",
      key: "store_name",
    },
    {
      title: "Store Price",
      width: 150,
      dataIndex: "store_price",
      key: "store_price",
    },
    {
      title: "Amazon Price",
      width: 150,
      dataIndex: "amazon_price",
      key: "amazon_price",
    },
    {
      title: "Net Profit",
      width: 150,
      dataIndex: "net_profit",
      key: "net_profit",
    },
    {
      title: "Amazon Rank",
      width: 150,
      dataIndex: "ninety_days_rank",
      key: "ninety_days_rank",
    },
    {
      title: "ROI",
      width: 150,
      render: (value) => {
        return (
          <>
            <div>{`${value?.roi} %`}</div>
          </>
        );
      },
    },
    {
      title: "Promotion",
      width: 150,
      dataIndex: "promotion_code",
      key: "promotion_code",
    },
    { title: "Notes", width: 150, dataIndex: "notes", key: "notes" },
    {
      title: "Fba",
      width: 150,
      dataIndex: "fba_seller_count",
      key: "fba_seller_count",
    },
    {
      title: "Fbm",
      width: 150,
      dataIndex: "fbm_seller_count",
      key: "fbm_seller_count",
    },
    {
      title: "Est Monthly Sales",
      width: 150,
      dataIndex: "estimated_monthly_sales",
      key: "estimated_monthly_sales",
    },
    { title: "Bsr", width: 150, dataIndex: "bsr", key: "bsr" },
    {
      title: "Hazmat",
      width: 100,
      key: "is_hazmat",
      render: (value) => {
        return (
          <>
            <div>
              <Switch size="small" defaultChecked={value?.is_hazmat} />
            </div>
          </>
        );
      },
    },
    {
      title: "Restricted",
      width: 100,
      key: "is_restricted",
      render: (value) => {
        return (
          <>
            <div>
              <Switch size="small" defaultChecked={value?.is_restricted} />
            </div>
          </>
        );
      },
    },
    { title: "UPC", width: 150, dataIndex: "upc_ean", key: "upc_ean" },
    {
      title: "Shipping Notes",
      width: 150,
      dataIndex: "shipping_notes",
      key: "shipping_notes",
    },
    {
      title: "Plan Type",
      width: 100,
      dataIndex: "plan_type",
      key: "plan_type",
    },
    {
      title: "Plan List Id",
      width: 150,
      dataIndex: "plan_list_id",
      key: "plan_list_id",
    },
    {
      title: "Added On",
      width: 120,
      key: "updated_on",
      render: (value) => {
        return (
          <>
            <div>{`${moment(value?.created_on).format("DD-MMM-YYYY")}`}</div>
          </>
        );
      },
    },
    {
      title: "Last Updated On",
      width: 120,
      key: "updated_on",
      render: (value) => {
        return (
          <>
            <div>{`${moment(value?.updated_on).format("DD-MMM-YYYY")}`}</div>
          </>
        );
      },
    },
  ];

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Wrapper>
      {dataArray?.length > 0 ? (
        <div style={{ marginLeft: "5px", marginRight: "5px" }}>
          <Table
            size="small"
            columns={columns}
            dataSource={dataArray}
            pagination={false}
            style={{ height: "80vh", overflow: "auto" }}
            scroll={{ x: 1500 }}
          />
          <Pagination
            style={{ float: "right" }}
            size="small"
            defaultCurrent={1}
            defaultPageSize={pageSizeValue}
            total={props?.AllData?.totalDocs}
            responsive={true}
            onChange={(value, pageSize) => {
              props?.PaginationUpdate(value, pageSize);
            }}
          />
        </div>
      ) : (
        <div>
          <div style={{ margin: "10%" }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      )}
      <div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Note archived"
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Deals successfully updated
          </Alert>
        </Snackbar>
      </div>
    </Wrapper>
  );
};
export default DealsViewTable;
