import { 
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  InputNumber,
  Input,
  Switch,
  Divider 
} from 'antd';
import React,{ useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { RightCircleOutlined, DownCircleOutlined,DeleteOutlined } from '@ant-design/icons';
import { CgAdd } from 'react-icons/cg';
import styled from 'styled-components';
import * as serviceCaller from "../../Services/plans";

const Wrapper=styled.div`
  .ant-row-middle {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
`;
const SwitchStyle=styled.div`
  .ant-switch-checked {
    background-color: #FF9900;
  }
`;

const PlanListView = (props) => {
  const [form] = Form.useForm();
  const [duplicatePlan, setDuplicatePlan] = useState(false);
  const [AddListModal, setAddListModal] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ SelectPlan, setSelectPlan ] = useState();
  const [refresh, setRefresh] = useState(false);
  const [Opened, setOpened] = useState();
  const [PlanListData, setPlanListData] = useState([]);
  
  useEffect(() => {
    setPlanListData(props?.data);
    setOpened(undefined);
  }, [props]);
  

  const addPlanList=async(values)=>{
    try {
      await serviceCaller.addPlan_list(values);
      setRefresh(!refresh);
      setAddListModal(false);
      enqueueSnackbar("Plan List Added", {
        variant: "success",
      });
      props?.reload();
    } catch (error) {
      console.log("Error : ",error);
      enqueueSnackbar("error while add plan", {
        variant: "error",
      });
    }
  }
  const EditPlanList=async(item)=>{
    try {
      const planDatas = await serviceCaller.editPlan_list(item?._id,item);
      enqueueSnackbar(`${item?.plan_list} list has been changed to live`, {
        variant: "success",
      });
      props?.reload();
    } catch (error) {
      console.log("Error : ",error);
      enqueueSnackbar("error while add plan", {
        variant: "error",
      });
    }
  }

  return(
    <Wrapper>
      <div>
        {PlanListData?.map((value)=>(
          <>
            <Row align='middle' justify='space-around'>
              <Col xs={2} sm={4} md={2} lg={2} xxl={2}>
                <div style={{display:"flex",alignItems:"center",fontSize:"18px"}}>
                  {Opened?._id===value?._id ? (
                    <DownCircleOutlined 
                      onClick={e => {
                        setOpened(undefined);
                      }} />
                    ) : (
                      <RightCircleOutlined 
                        onClick={e => {
                          setOpened(undefined);
                          setOpened(value);
                        }} />
                  )}
                </div>
              </Col>
              <Col xs={22} sm={10} md={6} lg={6} xxl={6}>
                <div style={{display:"flex",alignItems:"center",fontSize:"18px"}}>
                  <div style={{fontWeight:"bold"}}>{"Plans : "}</div>&nbsp;&nbsp;
                  <div style={{textTransform:"capitalize"}}>{value?.plan}</div>
                </div>
              </Col>
              <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                <div style={{display:"flex",alignItems:"center",fontSize:"18px"}}>
                  <div style={{fontWeight:"bold"}}>{"Limit : "}</div> &nbsp;&nbsp;
                  <div style={{textTransform:"capitalize"}}>{value?.limit}</div>
                </div>
              </Col>
              <Col xs={12} sm={24} md={7} lg={7} xxl={7}>
                <div>
                  <Button
                    type="primary"
                    style={{backgroundColor:"#FF9900",color:"#fff",border:"none"}}
                    // loading={loadings[1]}
                    onClick={() =>{
                      setSelectPlan(value);
                      setAddListModal(true);
                    }}
                  >
                    <div style={{display:"flex",alignItems:"center"}}>
                      <div style={{display:"flex",alignItems:"center"}}><CgAdd /></div>&nbsp;&nbsp;
                      <div>{"Add List"}</div>
                    </div>
                  </Button>
                </div>
              </Col>
            </Row>
            <div>
              {(Opened?._id===value?._id) && (
                  <div>
                    {Opened?.plan_lists.map((item)=>(
                      <>
                        <Row align='middle' style={{boxShadow:"none",border:"none",textAlign:"justify"}}>
                          <Col xs={12} sm={12} md={3} lg={3} xxl={3}>
                            <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>{item?.plan_list}</div>
                          </Col>
                          <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                            <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>{`Current user Count : 30`}</div>
                          </Col>
                          <Col xs={24} sm={24} md={0} lg={0} xxl={0}>
                            <div>
                              <a href={item?.url} target="_blank">{"Payment URL"}</a></div>
                          </Col>
                          <Col xs={12} sm={12} md={7} lg={7} xxl={7}>
                            <div style={{fontWeight:"bold",fontSize:"16px"}}>{item?.plan_url_id}</div>
                          </Col>
                          <Col xs={0} sm={0} md={4} lg={4} xxl={4}>
                            <div>
                              <a href={item?.url} target="_blank">{"Payment URL"}</a></div>
                          </Col>
                          <Col xs={9} sm={9} md={4} lg={4} xxl={4}>
                            <SwitchStyle>
                              <Button 
                                type='primary' 
                                style={{
                                  backgroundColor:item?.live ? "#0a0a0a" : "#fff",
                                  color:"#FF9900",
                                  border:"1px solid #FF9900",
                                  width:"68%"
                                }}
                                onClick={async()=>{
                                  EditPlanList(item);
                                }}
                              >
                                {item?.live? "Live" : "Not-In-Live"}
                              </Button>
                            </SwitchStyle>
                          </Col>
                          <Col xs={3} sm={3} md={2} lg={2} xxl={2}>
                              {/* <Button 
                                type='link' 
                                style={{
                                  color:"#FF9900",
                                }}
                                onClick={async()=>{
                                  const planDatas = await serviceCaller.deletePlan_list(item?._id,item);
                                  enqueueSnackbar(`${item?.plan_list} list has been deleted`, {
                                    variant: "success",
                                  });
                                  props?.reload();
                                }}
                              >
                                <DeleteOutlined />
                              </Button> */}
                          </Col>
                        </Row>
                        <Divider/>
                      </>
                    ))}
                  </div>
                )}
            </div>
          </>
        ))}
      </div>
      <div>
        <Modal 
          title={<div style={{textTransform:"capitalize"}}>{`Add ${SelectPlan?.plan} List`}</div>}
          open={AddListModal} 
          onClose={()=> {
            form.resetFields();
            setAddListModal(false);
          }}
          okText="Create"
          cancelText="Cancel"
          onCancel={()=> {
            form.resetFields();
            setAddListModal(false);
          }}
          onOk={() => {
            setDuplicatePlan(false);
            form.validateFields().then(async(values) => {
              values.plan_list=`${SelectPlan?.plan} ${values.plan_list}`;
              values.plan_id=SelectPlan?._id;
              const planDatas = await serviceCaller.getPricePlan(values);
              if(planDatas?.data?.plan_list){
                setDuplicatePlan(true);
              }else{
                addPlanList(values);
              }
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <div>
            <Form
              form={form}
              name="register"
              colon={false}
              onFinish={()=>{}}
              requiredMark={false}
              initialValues={{
                // residence: ['zhejiang', 'hangzhou', 'xihu'],
                // prefix: '86',
              }}
              scrollToFirstError
            >
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                  <Form.Item label="Plan List" />
                </Col>
                <Col xs={12} sm={12} md={18} lg={18} xxl={18}>
                  <Form.Item
                    name="plan_list"
                    validateStatus={duplicatePlan && "error"}
                    help={duplicatePlan && <span>{"Already Exist"}</span>}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your plan!',
                      },
                    ]}
                  >
                    <Input
                      addonBefore={`${SelectPlan?.plan}`}
                      style={{textTransform:"capitalize"}}
                      onChange={(e)=>{
                        setDuplicatePlan(false);
                      }}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                  <Form.Item label="Plan Id" />
                </Col>
                <Col xs={12} sm={12} md={18} lg={18} xxl={18}>
                  <Form.Item
                    name="plan_url_id"
                    // validateStatus={duplicatePlan && "error"}
                    // help={duplicatePlan && <span>{"Already Exist"}</span>}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your plan id!',
                      },
                    ]}
                  >
                    <Input
                      // style={{textTransform:"capitalize"}}
                      // onChange={async(e)=>{
                      //   let value=e?.target?.value;
                      //   value=value?.trime();
                      //   console.log("E value : ",value);
                      //   const planDatas = await serviceCaller.getPricePlan(value);
                      // }}
                      />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                  <Form.Item label="URL" />
                </Col>
                <Col xs={12} sm={12} md={18} lg={18} xxl={18}>
                  <Form.Item
                    name="url"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: 'url',
                        warningOnly: true,
                      },
                      {
                        type: 'string',
                        min: 6,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                  <Form.Item label="Live" />
                </Col>
                <Col xs={12} sm={12} md={18} lg={18} xxl={18}>
                  <Form.Item
                    valuePropName="checked"
                    name="live"
                  >
                    <Switch/>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      </div>
    </Wrapper>
  )};

export default PlanListView;